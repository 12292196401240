import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import PageHero from '../components/hero/page'
import Contact from '../components/contact'

import data from '../../injuryData.json'

import styles from '../styles/page.module.scss'

class ClaimPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}

    this.getInjuries = this.getInjurySet.bind(this)
  }

  componentWillMount () {
    this.setState({
      caseData: data.caseData,
      injuryTypes: data.injuryTypes,
      selectedSet: [],
    })
  }

  /**
   * Blah.
   *
   * @param {*} event Test #1.
   */
  getInjurySet (event) {
    const value = event.target.value

    this.setState({
      selectedSet: this.state.injuryTypes[value]
    })
  }

  render () {
    /**
     * Blah Test.
     */
    const renderOptions = () => {
      const keys = Object.keys(this.state.injuryTypes)
      const types = [
        <option value="none" disabled key="0">Select an Injury Type</option>
      ]

      keys.forEach((key, index) => types.push(
        <option name={ key } key={ index + 1 }>
          {key}
        </option>
      ))

      return types
    }

    /**
     * Also blah.
     *
     * @param {*} damageType Test Blah #1.
     * @param {*} damageAmount Test Blah #2.
     */
    const injuryListing = (damageType, damageAmount) =>
      damageType.map((type, index) => (
        <tr key={ index }>
          <td>{ type }</td>
          <td>{ damageAmount[index] }</td>
        </tr>
      ))

    /**
     * Blah.
     */
    const renderInjurySet = () => {
      const selectedSet = this.state.selectedSet

      if (selectedSet.length === 0) {
        return (
          <tr>
            <td colSpan="6">No injury type selected.</td>
          </tr>
        )
      }

      return selectedSet.map((position, index) => {
        let c = this.state.caseData[position]

        return (
          <tr key={ index }>
            <td>
              { c.caseDescription }
            </td>
            <td>
              { c.ageAtTrial || 'Age unavailable' }
            </td>
            <td>
              { c.occupation }
            </td>
            <td>
              { c.injuriesSustained }
            </td>
            <td colSpan="2">
              <table className={ styles.subClaimTable }>
                <tbody>
                  { injuryListing(c.headOfDamageTitle, c.headOfDamageAmount) }
                </tbody>
              </table>
            </td>
          </tr>
        )
      })
    }

    return (
      <Layout>
        <SEO
          title="What is my claim worth?"
          description={
            `To give you a rough idea of what you’re claim could be worth 
            we’ve pulled together a summary of the payment awards that the 
            Queensland Courts have made.`
          }
          canonical="https://motorinjuryhelpline.com.au/what-is-my-claim-worth"
        />

        <PageHero
          leadLine="BEEN INJURED IN A MOTOR ACCIDENT?"
          heroLine="HOW MUCH COULD YOU CLAIM?"
          heroPoints={
            [
              `Motor Injury Claims`,
              `Personal Injury Claims`,
              `10 years of data`
            ]
          }
        />

        <main>
          <div
            className={ styles.content }
            style={{
              maxWidth: `100%`,
            }}
          >
            <div className={ styles.content__container }>
              <p>
                To give you a rough idea of what you’re claim could be worth
                we’ve pulled together a summary of the payment awards that
                the Queensland Courts have made to people who have been
                injured over the last decade. This is only a guide, and your
                individual case would need to be discussed, reviewed and
                assessed.<br/>
                <br/>
                Simply select the type of injury from the drop down list and
                the cases will be displayed in a table below. Please note
                these cases cover all personal injury claims, not only motor
                vehicle injuries because the assessment of damages is in most
                cases similar as they are based on the same laws.<br/>
                <br/>
                Contact us today if you have any questions or would like one
                of our injury law experts to assess your case to find out
                how much your claim is worth.<br/>
                <br/>
                <select
                  defaultValue="none"
                  onChange={ event => this.getInjurySet(event) }
                >
                  { renderOptions() }
                </select>
              </p>
              <div className={ styles.mobileScroll }>
                <table className={ styles.claimTable }>
                  <tbody>
                    <tr>
                      <th>Case and Judge(s)</th>
                      <th>Age at Trial</th>
                      <th>Occupation</th>
                      <th>Injury Sustained, Severity and Type of
                      Claim</th>
                      <th colSpan="2">Award of Damages</th>
                    </tr>
                    <tr>
                      <th colSpan="4"></th>
                      <th>Head of Damage</th>
                      <th>Amount</th>
                    </tr>
                    { renderInjurySet() }
                  </tbody>
                </table>
              </div>
              <p>
                To discuss how your claim may compare to some of these
                Queensland Court awards and to get an understanding of how
                your claim would be calculated and an estimate of what your
                claim is worth, contact us now for a free, no obligation
                assessment from one of our expert senior lawyers.
              </p>
            </div>
          </div>
          <Contact />
        </main>

      </Layout>
    )
  }
}

export default ClaimPage
